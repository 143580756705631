import React from 'react'
import CardItem from "./CardItem";
import CustomButton from '../CustomButton';
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const CardBox = ({
  name,
  buttons = [],
  values = [],
  formatter,
  handleSelect,
  handleCheck,
  isSelect,
  checked,
  showBorder = true,
  showCheck = false,
  element = () => { },
}) => {
  buttons = buttons.filter(item => item)

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid container justifyContent={"space-between"} alignItems={"center"} mb={2}>
        <Grid>
          <h3>{name}</h3>
        </Grid>
        <Grid sx={{ mr: 1 }}>
          {
            buttons.map((button, key) => (
              <CustomButton
                id={button.id}
                icon={button.icon}
                key={button.key + key}
                name={button.name}
                click={button.click}
              />
            ))
          }
        </Grid>
      </Grid>
      {
        values.length === 0 ? (<p>Sin {name}</p>) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              {
                values.map((item) => typeof formatter === 'function' ? formatter(item) : item).map((item, key) => (
                  <CardItem
                    key={key}
                    name={item.name}
                    src={item.image}
                    active={item.id === isSelect}
                    checked={checked && checked(item)}
                    click={() => handleSelect && handleSelect(item)}
                    onCheck={(check) => handleCheck && handleCheck(item, check)}
                    showBorder={showBorder}
                    showCheck={showCheck}
                  >
                    {element(item)}
                  </CardItem>
                ))
              }
            </Grid>
          </Box>
        )
      }
    </Grid>
  );
}

export default CardBox